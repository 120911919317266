import React, { useRef } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import {laptop} from '../../imports';
import './contact.css'

function Contact() {
    const formRef = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData.entries());

        try {
            const response = await axios.post('/api/sendMail', data);
            if (response.status === 200){
                toast.success("Email sent Successfully!");
                formRef.current.reset();
            }
        } catch (error) {
            console.error('Error sending message', error);
            toast.error("Failed to send email.");
        }
    }

  return (
    <div className={`contact-container`}>
        <div className={`contact-left`}>
            <h1>Let's talk about your project.</h1>
            <div className={`contact-left-img`}>
                <img src={laptop} alt='laptop'/>
            </div>
        </div>

        <div className={`contact-right`}>
            <div className={`contact-right-form-title`}>
                <p>
                    <b>PLEASE</b> fill out this form
                </p>
            </div>
            <form onSubmit={handleSubmit} ref={formRef} >
                <label htmlFor="id-name"></label>
                <input 
                    id='id-name'
                    name='name'
                    placeholder='Name'
                    type='text'
                    required
                    className={`form-input`}
                    autoComplete='on'
                />
                <label htmlFor="id-email"></label>
                <input 
                    id='id-email'
                    name='email'
                    placeholder='Email'
                    type='email'
                    required
                    className={`form-input`}
                    autoComplete='on'
                />
                <label htmlFor="id-phone"></label>
                <input 
                    id='id-phone'
                    name='phone'
                    placeholder='Phone'
                    type='text'
                    className={`form-input`}
                    autoComplete='on'
                />
                <label htmlFor="id-select"></label>
                <select className={`form-select`} name='service' id='id-select'>
                    <option value="">Choose Services...</option>
                    <option value="Parking">Parking</option>
                    <option value="Hotels">Hotels</option>
                    <option value="Wedding">Wedding</option>
                    <option value="Events">Events</option>
                    <option value="Conferences">Conferences</option>
                    <option value="Restaurants">Restaurants</option>
                    <option value="Other">Other...</option>
                </select>
                <textarea 
                    name='message'
                    placeholder='Message...'
                    maxLength="500"
                    className='form-textarea'
                    autoComplete='off'
                />
                <input 
                    name='submit'
                    type='submit'
                />
            </form>
        </div>
    </div>
  )
}

export default Contact