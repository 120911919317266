import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navbar, Hero, Brands, Mission, About, Contact, Call, Footer } from './components';
import { ContactPage, ServicesPage, AboutPage } from './pages';

import './App.css';

function App() {
  return (
    <Router>

      <div className="App">
        <Navbar />
        <Routes>
          <Route
            path="/" element={<>
                                <Hero />
                                <Brands />
                                <Mission />
                                <About />
                                <Contact />
                                <Call />
                            </>}
          />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicesPage />} />
        </Routes>
        <Footer />
      </div>
      <ToastContainer />
    </Router>
  );
}

export default App;
