import React from 'react'
import './newsletter.css'

const Newsletter = () => {
  return (
    <div>
        <div className= {`cta-container`}>
                <div className={`cta-text`}>
                    <h4>
                        Subscribe
                    </h4>
                    <p>
                        Stay in touch to hear from our latest news
                    </p>
                </div>

                <div className= {`cta-input-container`}>
                    <div className={`cta-input-email`}>
                        <input type='email' placeholder='Email' />
                    </div>
                    <div className={`cta-input-submit`}>
                        <button type='submit' > Sign up </button>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Newsletter