import React from 'react'
import './about.css'
import { carKey, CarLuxury } from '../../imports'


function About() {
    return (
        <div className={`about-container`}>

            {/* left */}
            <div className={`about-text-container`}>
                <div className={`about-title`}>
                    <h1>About Us</h1>
                </div>
                {/* Image added here for mobile view */}
                <div className={`about-img-mobile`}>
                    <img src={carKey} alt='car key' />
                </div>
                <div className={`about-text`}>
                    <p>
                        Luxe Valet is a Montreal-based company that specializes in providing luxury valet services. Our team is comprised of skilled professionals who are dedicated to ensuring a hassle-free experience for our valued clients.
                    </p>
                    <p>
                        We pride ourselves on delivering a stress-free experience that meets your highest expectations.
                    </p>
                </div>
            </div>

            {/* right */}
            <div className={`about-img-grids-container`}>
                <div className={`about-img-col-1`}>
                    <img src={carKey} alt='car key' />
                </div>

                <div className={`about-img-col-2`}>
                    <div className={`about-img-row-1`}>
                        <h1>+4 Team</h1>
                        <p>Experience</p>
                    </div>
                    <div className={`about-img-row-2`}>
                        <img src={CarLuxury} alt='car' />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default About