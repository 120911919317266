import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import 'font-awesome/css/font-awesome.min.css';



function Footer() {
    return (
        <div>
            <footer>
                <div className="footer">
                    <div class="footer-row">
                        <a href="https://www.facebook.com/LUXEVALETINC" target="_blank" rel="noopener noreferrer" ><i class="fa fa-facebook"></i></a>
                        <a href="https://www.instagram.com/luxevalet.inc/" target="_blank" rel="noopener noreferrer" ><i class="fa fa-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/luxe-valet-ca/" target="_blank" rel="noopener noreferrer" ><i class="fa fa-linkedin"></i></a>
                        <a href="https://twitter.com/luxevaletinc" target="_blank" rel="noopener noreferrer" ><i class="fa fa-twitter"></i></a>
                    </div>

                    <div className="footer-row">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About us</Link></li>
                            <li><Link to="/services">Our Services</Link></li>
                            <li><Link to="/contact">Contact us</Link></li>
                        </ul>
                    </div>

                    <div className="footer-row">
                        Luxe Valet Copyright © 2023  - All rights reserved || Designed By: Elkana
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer