import React from 'react'
import { carKey_2 } from '../../imports'
import './contactPage.css'
import {Contact} from '../../components'

function ContactPage() {

    return (
        <div className={`contact-page-container`}>
            <div className='contact-header-photo'>
                <img src={carKey_2} alt='person holding car keys'/>
            </div>
            <div className='contact-page-title'>
                <h1>
                    <i>↓</i> Contact us
                </h1>
            </div>
            <div className='contact-form'>
                <Contact />
            </div>
        </div>
    )
}

export default ContactPage