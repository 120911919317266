import React from 'react'
import {Hotels, carParked, Events, Restaurants, Wedding, Conference} from '../../imports'
import './mission.css'


const imgData =[
    {
      title:'Hotels & Buildings',
      txt:'Trust us with your hotel parking',
      photo:Hotels,
    },
    {
      title:'Valet Services',
      txt:'Trust us with parking',
      photo:carParked,
    },
    {
      title:'Events',
      txt:'We got you!',
      photo:Events,
    },
    {
      title:'Restaurants',
      txt:'Simply worry about your food.',
      photo:Restaurants,
    },
    {
      title:'Wedding Services',
      txt:'Just Marry and we got your cars',
      photo:Wedding,
    },
    {
      title:'Conferences',
      txt:'Simply worry about your picth on stage.',
      photo:Conference,
    },
  
  ]
  

function Mission() {
  return (
    <div className='mission-container'>
        <div className='mission-title'>
           <h1> Luxury Services </h1>
        </div>
        <div className='mission-data-container'>
          {
            imgData.map((data, key) => 
            <a href={`#${data.title.toLowerCase().replace(/\s+/g, '-')}`} key={key+data.txt} className='mission-data'>
              <div className={`mission-img`}>
                <img src={data.photo} alt={data.title} loading='lazy'/>
              </div>
                <div className={`mission-bio`}>
                  <h1>{data.title}</h1>
                  <p>{data.txt}</p>
                </div>
              </a>
            )
          }
        </div>
    </div>
  )
}

export default Mission