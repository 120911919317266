import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { logo, logo_luxe_valet, logo_luxe_valet_2, openBtn, closeBtn } from '../../imports'
import './navbar.css'

const Navbar = () => {
  const [selectedMenu, setSelectedMenu] = useState('Home');
  const [openMenu, setOpenMenu] = useState(false);

  const menuPages = [
    { name: 'Home', url: '/' },
    { name: 'About Us', url: '/about' },
    { name: 'Services', url: '/services' },
    { name: 'Contact Us', url: '/contact' }
  ]
  const handleMenuClick = (menuName) => {
    setSelectedMenu(menuName);
  }

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  }

  return (
    <div className={`navbar-container  ${openMenu ? 'open' : ''} `}>
      <div className={`navbar-logo`}>
        <Link to="/"><img src={logo_luxe_valet} alt="logo" /></Link>
      </div>

      <div className={`navbar-menu`}>
        {
          menuPages.map((menuItem) => (
            <p
              key={menuItem.name}
              onClick={() => handleMenuClick(menuItem.name)}
              className={`${selectedMenu === menuItem.name ? 'selected' : ''}`}
            >
              <Link to={menuItem.url}>
                {menuItem.name}
              </Link>
            </p>

          ))
        }
      </div>

      {/* Mobile Menu */}
      <div className={`mobile-menu-container`}>
        <div className={`mobile-menu-logo`}>
          <Link to="/"><img src={logo_luxe_valet} alt="logo" /></Link>
        </div>
        <div>

          <img src={ openBtn} alt='open button' onClick={toggleMenu} />
        </div>
      </div>
      {
        openMenu &&
        <div className={`mobile-menu`}>
          <div>

            <img src={closeBtn} alt='close button' onClick={toggleMenu} />
          </div>
          {
            menuPages.map((menuItem) => (
              <p
                key={menuItem.name}
                onClick={() => handleMenuClick(menuItem.name)}
                className={`${selectedMenu === menuItem.name ? 'selected' : ''}`}
              >
                <Link to={menuItem.url}>
                  {menuItem.name}
                </Link>
              </p>

            ))
          }
        </div>
      }
    </div>

  )
}

export default Navbar