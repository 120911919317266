import React from 'react';
import Marquee from 'react-fast-marquee';
import {
  logo_motrec,
  logo_crooks_and_castles,
  logo_condominius,
  logo_wilkar,
  logo_batimat,
  logo_mau_mtl,
} from '../../imports';
import './brands.css';

const partners_logo = [logo_motrec, logo_crooks_and_castles,  logo_mau_mtl, logo_condominius, logo_batimat, logo_wilkar]

function Brands() {
  return (
    <div className={`brands-container`}>
      <div className={`brands-title`}>
        <h1>
          Our Clients
        </h1>
      </div>

        <Marquee className='marquee-animation' loop={0}>
          <div className={`brands-logos`} >
            {
              partners_logo.map((aLogo, aKey) =>
                <div className={`brands-img`} >
                  <img src={aLogo} alt={aKey} key={aKey} />
                </div>
              )
            }
          </div>
        </Marquee >
    </div >
  )
}

export default Brands