import React from 'react'
import './aboutPage.css'
import { carKey_2, CarValet } from '../../imports'
import { Brands, Newsletter } from '../../components/'


const ownerData = [
    {
        txt: "We elevate the standard of luxury and professionalism in every aspect. Our commitment to impeccable service, meticulous attention to detail, and a tailored approach distinguishes us in the valet industry. Luxe Valet understands the significance of entrusting us with your vehicle. This is why we ensure that our valets undergo extensive training, guaranteeing not only the safest handling of your vehicle but also delivering exceptional customer service. Our efficiency, reliability, and discretion are the pillars that make us the preferred choice for those who value their time and seek peace of mind. Beyond just parking cars, we offer a luxurious, seamless experience from the moment you arrive to when you depart. Luxe Valet specializes in catering to a variety of needs, including high-end events, corporate functions, private gatherings, and sophisticated hotel valet services. Partnering with hotels, we strive to extend your luxury experience right from the curb, enhancing the first impressions of your esteemed guests. Choose Luxe Valet for a sophisticated touch to every occasion, where luxury isn't just a promise—it's an experience we guarantee.",
        photo: CarValet,
    }

]

const headerTextData = [
    {
        title: 'Our Background',
        paragraph: " Founded on the principles of reliability, discretion, and elegance, our mission is to provide a seamless, secure, and sophisticated service to each client. Our team of expertly trained valets is not just skilled in safe and efficient vehicle handling but also in delivering an exceptional customer service experience. "
    },
    {
        title: 'Our Story',
        paragraph: " Every interaction with Luxe Valet is more than just parking; it's about providing a touch of luxury to your journey. We cater to a diverse clientele, ranging from high-end events to exclusive private gatherings, ensuring that first impressions are not just made but meticulously crafted. "
    },
    {
        paragraph: " Trust Luxe Valet to add a layer of convenience and prestige to your event, reflecting the high standards you hold. "
    },
]

function AboutPage() {

    return (
        <div className={`about-page-container`}>
            <div className='about-header-photo'>
                <img src={carKey_2} alt='person holding car keys' />
            </div>
            <div className='about-page-title'>
                <h1>
                    <i>↓</i> Who We are
                </h1>
                <p>
                    Where luxury meets functionality, and every detail is tailored to surpass your expectations.
                </p>
            </div>
            <div className='about-aim-container'>
                {
                    headerTextData.map((data, key) =>
                        <div className={`about-aim-title`} key={key + data.paragraph}>
                            <h5>
                                {data.title}
                            </h5>
                            <p> {data.paragraph} </p>
                        </div>
                    )}
            </div>
            <div className={`about-data-container`} >
                <div className={`about-data-title`}>
                    <h5>Work with us</h5>
                </div>
                {ownerData.map((data, key) =>
                    <div
                        key={key + data.txt}
                        className={`about-data`}
                    >
                        <div className={`about-data-txt`} >
                            <p>{data.txt}</p>
                        </div>
                        <div className={`about-data-img`} >
                            <img src={data.photo} alt='about' />
                        </div>
                    </div>
                )}
            </div>
            <div className={`about-brands-container`}>
                <Brands />
            </div>
            <div className={`about-newsletter-container`}>
            <Newsletter />
            </div>
        </div>
    )
}

export default AboutPage