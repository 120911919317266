import React from 'react'
import './call.css'
import { car_rolls_royce } from '../../imports'

const Call = () => {
    return (
        <div className={`call-container`}>
            <div className={`call-box-container`}>

                <div className={`call-box-left-img`}>
                    <img src={car_rolls_royce} alt='car' />
                </div>

                <div className={`call-box-right-text`}>

                    <div className={`call-box-right-email`}>
                        <h4>
                            Email
                        </h4>
                        <p>
                            support@luxevalet.ca
                        </p>
                    </div>
                    <div className={`call-box-right-call`}>
                        <h4>
                            Call
                        </h4>
                        <p>
                            +1 438-337-2022
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Call