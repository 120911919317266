import React, { useState } from 'react'
import { Hotels, carKey_2, carParked, Events, Restaurants, Wedding, Conference } from '../../imports'
import { Newsletter } from '../../components/'
import './servicesPage.css'


const serviceData = [
    {
        title: "Hotels & Buildings",
        txt: "Our distinguished valet company specializes in delivering a comprehensive suite of services tailored exclusively for hotels and commercial buildings. We take pride in offering a wide array of high-quality amenities designed to elevate the guest and tenant experience to new heights. Our team of professional valet attendants excels in seamlessly handling vehicle parking and retrieval services, ensuring that every interaction exudes the utmost professionalism and commitment to customer satisfaction. Moreover, we go the extra mile by providing exceptional luggage assistance, helping guests and residents with their bags to enhance their overall convenience and comfort. With a proven track record of managing high volumes of vehicles efficiently, we guarantee a well-organized parking system and a smooth traffic flow. Whether you are a hotel aiming to enhance guest satisfaction or a commercial building focused on offering convenience and luxury, our valet services, including luggage assistance, embody a level of excellence that leaves an indelible impression on all who experience it.",
        photo: Hotels,
    },
    {
        title: "Valet Services",
        txt: "Our valet company is dedicated to providing comprehensive parking services that cater to the diverse needs of our clients. With a focus on efficiency and professionalism, we offer a range of solutions tailored to optimize parking operations. We excel in maximizing space utilization, minimizing wait times, and enhancing overall customer satisfaction. Our valet services are designed to exceed expectations and ensure a smooth, well-organized parking system. Whether it's a commercial facility, event venue, or a residential complex, ",
        photo: carParked,
    },
    {
        title: "Events",
        txt: "Our valet company offers a range of professional valet services tailored to enhance your events. With a keen focus on enhancing valet solutions that elevate the guest experience. Our services encompass valet parking coordination, ensuring a seamless flow of vehicles and efficient parking management. Additionally, we can offer valet attendants trained in hospitality and event etiquette, guaranteeing a warm and welcoming atmosphere for event attendees. Our commitment to excellence extends to providing customized signage, traffic management, and logistical support to optimize parking logistics for events of all sizes. Whether it's a corporate function, gala, wedding, or any special occasion, our valet services are an integral part of creating a memorable and hassle-free event experience.",
        photo: Events,
    },
    {
        title: "Restaurants",
        txt: "Our valet company specializes in providing exceptional valet services tailored specifically for restaurants. We offer a seamless and convenient parking experience for diners, ensuring a memorable beginning to their culinary journey. Our team of courteous and professionally trained valet attendants is committed to efficient vehicle parking and retrieval, guaranteeing a stress-free dining experience. We prioritize safety and customer satisfaction by carefully managing the flow of vehicles, minimizing wait times, and optimizing parking space utilization. Furthermore, our valet services can be customized to meet the unique needs and ambiance of each restaurant, enhancing the overall atmosphere and leaving a lasting impression on patrons. Whether it's an upscale fine dining establishment or a bustling eatery, our valet services contribute to the success and reputation of any restaurant by offering a touch of elegance and convenience for every guest.",
        photo: Restaurants,
    },
    {
        title: "Wedding Services",
        txt: "Our valet company specializes in delivering an exceptional valet service tailored to weddings, ensuring that your special day is marked by elegance, convenience, and a seamless guest experience. We provide professional valet attendants who are skilled in managing wedding-related logistics, offering efficient vehicle parking and retrieval services while maintaining a courteous and friendly demeanor. Our team takes pride in ensuring that the arrival and departure of guests are smooth and hassle-free, allowing the couple and their loved ones to focus on the celebration. We also offer customizable signage, traffic coordination, and the option to enhance the valet service to match the wedding's unique theme or ambiance. With our attention to detail and commitment to excellence, we add an extra layer of sophistication and convenience to your wedding day, leaving you with cherished memories and glowing reviews from your guests.",
        photo: Wedding,
    },
    {
        title: "Conferences",
        txt: "Our valet company specializes in delivering tailored valet services designed to enhance the overall experience at conferences and corporate events. With a keen focus on efficiency and professionalism, we provide seamless parking solutions that optimize the arrival process for attendees. Our team of experienced valet attendants ensures swift and organized vehicle parking and retrieval, minimizing wait times and streamlining traffic flow. We offer customizable services to meet the unique requirements of each conference, including VIP parking areas, shuttle services, and valet stands strategically placed for easy access. Our commitment to impeccable service helps create a positive first impression for conference participants, setting the tone for a successful and well-managed event. Whether it's a large-scale industry conference or a smaller corporate gathering, our valet services contribute to the overall success and satisfaction of attendees, making their experience truly exceptional.",
        photo: Conference,
    },

]

const aimData = [
    {
        paragraph: "We are dedicated to setting new standards of excellence by providing our clients with impeccable valet solutions that leave a lasting impression. Our mission is to consistently exceed expectations, whether it's in the hospitality industry, at special events, or within commercial settings."
    },
    {
        paragraph: "We aim to seamlessly integrate our services into our clients' operations, enhancing their brand image, customer satisfaction, and overall efficiency. By delivering top-notch service, meticulous attention to detail, and a commitment to the highest levels of professionalism, we aspire to be the trusted partner that elevates the experience for both clients and their patrons."
    }
]

function ServicesPage() {
    const [openService, setOpenService] = useState('Parking Management');
    const handleService = (title) => {
        setOpenService(openService === title ? '' : title);
    }

    return (
        <div className={`service-page-container`}>
            <div className='service-header-photo'>
                <img src={carKey_2} alt='person holding car keys' />
            </div>
            <div className='service-page-title'>
                <h1>
                    <i>↓</i> Our Services
                </h1>
                <p>
                    We redefine convenience, luxury, and professionalism in the realm of parking and hospitality services.
                </p>
            </div>
            <div className='service-aim-container'>
                <div className={`service-aim-title`}>
                    <h5>
                        See the full list our services below
                    </h5>
                    {
                        aimData.map((data, key) =>
                            <p key={key + data.paragraph}> {data.paragraph} </p>
                        )}
                </div>
            </div>
            <div className={`service-data-container`} >
                <div className={`service-data-title-container`} >
                    {serviceData.map((data, key) =>
                        <div
                            key={key + data.title}
                            className={` ${openService === data.title ? 'service-data-title-selected' : 'service-data-title'}`}
                            onClick={() => handleService(data.title)}
                        >
                            <h5>{data.title}</h5>
                        </div>
                    )}
                </div>
                <div className={`service-data-text-container`} >
                    {serviceData.map((data, key) =>
                        <div
                            key={key + data.txt}
                            className={`service-data-text-img`}
                        >
                            {
                                openService === data.title &&
                                <>
                                    <div className={`service-data-txt`}>
                                        <p>{data.txt}</p>
                                    </div>
                                    <div className={`service-data-img`}>
                                        <img src={data.photo} alt='service data' />
                                    </div>
                                </>
                            }

                        </div>
                    )}
                </div>
            </div>
            <Newsletter />
        </div>
    )
}

export default ServicesPage