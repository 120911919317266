import React from 'react';
import { useNavigate } from 'react-router-dom';
import './hero.css';

const Hero = () => {
  const navigate = useNavigate();

  const navigateToServices = () => {
    navigate('/services');
  };

  const navigateToAbout = () => {
    navigate('/about');
  };

  return (
    <div className={`hero`}>
      <div className={`hero-title`}>
        <div>
          <h1>
            Montreal's Luxury Valet Service
          </h1>
        </div>
        <div>
          <button onClick={navigateToServices}>Browse services →</button>
        </div>
      </div>
      <div className={`hero-stats`}>
        <div>
          <h5>Team Experience</h5>
          <p>4+</p>
        </div>
       
        <div>
          <h5>Customizable Service</h5>
          <p>100%</p>
        </div>
        <div>
          <button onClick={navigateToAbout}>{ }</button>
        </div>
      </div>
    </div>
  )
}

export default Hero